<template>
  <div class="column is-4 has-background-light order-recap-sidebar">
    <p><strong class="txt-yellow is-uppercase">Resumen del pedido</strong></p>
    <template v-for="product in shoppingCart.items">
      <div class="recap-service-wrap" v-bind:key="product.id">
        <div class="recap-service-icon">
          <span class="icon-tooth"></span>
        </div>
        <div class="recap-service-info">
          <p>
            <span v-if="product.extra">[EXTRA] </span><strong>{{product.product_name}}</strong><br>
            {{product.first_name + ' ' + product.last_name}}<br>
            CANTITAD {{product.quantity}}
          </p>
          <p>
          <a class="is-underlined" href="#" @click="editProduct(product)">Editar</a> <span class="icon-review"></span>

          <a class="is-underlined pl-5" href="#" @click="removeProduct(product)">Eliminar</a> <span class="icon-remove"></span></p>
        </div>
        <div class="recap-service-price">{{product.total_price / 100}} €</div>
      </div>
    </template>

    <hr>

    <div class="recap-line">
      <div class="line-left">
        <p><strong class="txt-yellow is-uppercase">SUBTOTAL</strong></p>
      </div>
      <div class="line-right">
        <p>{{shoppingCart.subtotal_price / 100}} €</p>
      </div>
    </div>

    <div class="recap-line">
      <div class="line-left">
        <p><strong class="txt-yellow is-uppercase">Coste de envío estimado</strong></p>
      </div>
      <div class="line-right">
        <p>{{shoppingCart.total_shipping_price / 100}} €</p>
      </div>
    </div>

    <template v-for="shippingLine in shoppingCart.shipping_lines">
      <div class="recap-line" v-bind:key="shippingLine.laboratory.id">
        <div class="line-left">
          <p>{{shippingLine.laboratory.name}}</p>
        </div>
        <div class="line-right">
          <p v-if="shippingLine.price > 0">{{shippingLine.price / 100}} €</p>
          <p v-if="shippingLine.price === 0">GRATIS</p>
        </div>
      </div>
    </template>

    <p><strong class="txt-yellow is-uppercase">Código promocional</strong></p>

    <div class="recap-line mt-2">
      <div class="line-left has-input">
        <div class="field">
          <input-text :placeholder="'Código*'" v-model="discountCode" :name="'discountCode'"></input-text>
        </div>
      </div>
      <div class="line-right has-input">
        <button class="button is-primary is-fullwidth" :disabled="true">Aplicar</button>
      </div>
    </div>

    <div class="recap-line">
      <div class="line-left">
        <p><strong class="txt-yellow is-uppercase">TOTAL</strong></p>
      </div>
      <div class="line-right">
        <p>{{shoppingCart.total_price / 100}} €</p>
      </div>
    </div>

    <hr>

    <h4 class="title is-size-4">¿Necesitas ayuda?</h4>
    <p>
      Cras mattis consectetur purus sit amet fermentum. Etiam porta sem malesuada magna mollis euismod. Donec sed odio dui. Curabitur blandit tempus porttitor.
    </p>
    <ul class="mt-3 mb-3">
      <li>
        <a class="is-underlined" href="#">Contactar con nosotros</a>
      </li>
      <li>
        <a class="is-underlined" href="#">Ver la sección de ayuda</a>
      </li>
      <li>
        <a class="is-underlined" href="#">Política de envíos y devoluciones</a>
      </li>
    </ul>
  </div>
</template>

<script>
/* import {
  IsEmptyOrNull
} from '@/utils/utils.js' */
import { mapGetters } from 'vuex'
import InputText from '@/components/FormElements/InputText.vue'
export default {
  name: 'OrderRecapSidebar',
  components: {
    'input-text': InputText
  },
  data () {
    return {
      discountCode: '',
      extras: []
    }
  },
  computed: {
    ...mapGetters({
      shoppingCart: 'clinic/shoppingCart',
      isLoggedIn: 'user/isLoggedIn',
      clinic: 'user/clinic'
    })
  },
  methods: {
    goToProducts () {
      this.$router.push({ path: '/products' })
    },
    removeProduct (product) {
      const self = this
      this.$store.dispatch('clinic/deleteShoppingCartItem', {
        cartItemId: product.id,
        cb: (response) => {
          self.fetchShoppingCart()
        },
        cbError: (error) => {
          self.$store.commit({
            type: 'application/ADD_FEEDBACK',
            feedback: {
              type: 'error',
              message: error.response.data.error
            }
          })
        }
      })
    },
    editProduct (cartItem) {
      if (this.isLoggedIn) {
        this.openOrderDetail(cartItem)
      } else {
        this.openLogInModal(cartItem)
      }
    },
    openOrderDetail (cartItem) {
      const modalOptions = {
        componentId: 'CompleteOrderDetails',
        title: 'Revisar los detalles de la orden',
        laboratory: cartItem.laboratory,
        productId: cartItem.product_id,
        productName: cartItem.product_name,
        productOfferId: cartItem.product_offer_id,
        productOfferPrice: cartItem.unit_price,
        patientName: cartItem.first_name,
        patientLastName: cartItem.last_name,
        odontologistName: cartItem.odontologist_name,
        shoppingCartItem: cartItem,
        activeExtras: this.extras,
        smallSize: false,
        isUpdate: true,
        isOrder: false,
        isReview: false,
        readOnly: false,
        updateCb: this.fetchShoppingCart
      }
      this.$store.commit({
        type: 'application/SHOW_MODAL',
        modalOptions: modalOptions
      })
    },
    openLogInModal (cartItem) {
      const self = this
      const modalOptions = {
        componentId: 'LoginRegister',
        title: 'Inicia sesión o registrate para seguir',
        smallSize: false,
        closeCb: () => {
          self.openOrderDetail(cartItem)
        }
      }
      this.$store.commit({
        type: 'application/SHOW_MODAL',
        modalOptions: modalOptions
      })
    },
    //
    initCartSummary () {
      this.extras = []
      for (let i = 0; i < this.shoppingCart.items.length; i++) {
        if (this.shoppingCart.items[i].extra) {
          this.extras.push(this.shoppingCart.items[i])
        }
      }
    },
    fetchShoppingCart () {
      const self = this
      this.$store.dispatch('clinic/getShoppingCart', {
        clinicId: this.clinic.id,
        cb: (response) => {
          if (self.shoppingCart.items.length === 0) {
            self.goToProducts()
          }
          self.initCartSummary()
        }
      })
    }
  },
  created () {
    this.fetchShoppingCart()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .order-recap-sidebar{
    border-radius: 8px;
    padding: 21px 28px;
    hr{
      height: 1px;
      background-color: #57567C;
    }
    .recap-service-wrap{
      display: flex;
      margin-top: 21px;
      &:last-child{
        margin-bottom: 21px;
      }
      .recap-service-icon{
        position: relative;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        box-shadow: 0 2px 24px 0 rgba(0,0,0,0.2);
        margin-right: 14px;
        .icon-tooth{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 1.5rem;
          color: #57567C;
        }
      }
      .recap-service-info{
        width: calc(100% - 104px);
      }
      .recap-service-price{
        width: 50px;
        text-align: right;
      }
    }
    .recap-line{
      width: 100%;
      display: flex;
      margin-bottom: 14px;
      .line-left{
        width: calc(100% - 50px);
        &.has-input{
          width: calc(100% - 114px);
          margin-right: 14px;
        }
      }
      .line-right{
        text-align: right;
        width: 50px;
        &.has-input{
          width: 100px;
        }
      }
    }
  }
</style>
